import { ActionType } from "../enums";

export class Action {
    type: ActionType;
    params: any[];

    constructor(
        type: ActionType,
        params?: any[],
    ) {
        this.type = type;
        this.params = params;
    }

}
