import { ActionType } from "../enums";
import { Action } from "../models";


export function postMessageCloseAndProceed() {
    const action = new Action(ActionType.CloseAndProceed)
    postMessage(action);
}

export function postMessageCancel() {
    const action = new Action(ActionType.Cancel)
    postMessage(action);
}
export function postMessageShare(url: string) {
    const params = [url]
    const action = new Action(ActionType.Share, params)
    postMessage(action);
}
export function postMessageOpenUrl(url: string) {
    const params = [url]
    const action = new Action(ActionType.OpenExternalUrl, params)
    postMessage(action);
}

function postMessage(action: Action) {
    if ((window as any).webkit && (window as any).webkit.messageHandlers) {
        console.log('Message sent!');
        (window as any).webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(action));
    }
    else {
        console.warn('Cordova IAB postMessage API not found!');
    }
}
